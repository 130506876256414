import React from 'react'
import { useEffect } from "react";
import { Button, Space, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import { IconDownloadFile } from "../../icons";
import {
  getDocumentData,
  getLoaders,
} from "../../store/documentation/selectors";
import { getDocFile, getDocumentsList} from "../../store/documentation";

function SandboxDocumentationArea({activeKey}) {
  const dispatch = useDispatch();
  const isLoader = useSelector(getLoaders);
  const docData = useSelector(getDocumentData);

  useEffect(() => {
    if (activeKey===3){
      dispatch(getDocumentsList({ service: "sandbox" }));
    }
  }, [activeKey]);

  const downloadFile = async (file) => {
    let stringType = file.doc_file.slice(-5);
    let index = stringType.lastIndexOf(".");
    dispatch(
      getDocFile({
        name: file.name,
        typeFile: stringType.slice(index + 1),
        id: file.id
      })
    );
  };
  const columns = [
    createColumn("Название", "name"),
    createColumn("Описание", "description"),
    createColumn("Изменено", "date_modified"),
    createColumn("Скачать", "doc_file", (text, row) => (
      <Button
        size="small"
        type="text"
        icon={<IconDownloadFile style={{ color: "#8090B8" }} />}
        onClick={() => {
          downloadFile(row);
        }}
        title={text}
      ></Button>
    )),
  ];
  return (
    <Space
      direction="vertical"
      size={20}
      style={{ width: "100%", position: "relative", opacity: isLoader ? 0.3 : 1  }}
    >
      {isLoader && <PreloaderNew />}

      <Table
        columns={columns}
        size="small"
        rowKey={(obj) => obj.id}
        pagination={false}
        style={{ overflowX: "auto" }}
        className="table"
        dataSource={docData?.results}
      />
    </Space>
  );
}

function createColumn(title, key, render) {
  return { title, key, dataIndex: key, render };
}
export default SandboxDocumentationArea;
