import React, { useEffect } from "react";
import { Button, Card, Divider, Space, Table, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import { IconDownloadFile } from "../../icons";
import {
  getDocFile,
  getDocumentsList,
  resetData,
} from "../../store/documentation";
import {
  getDocumentData,
  getLoaders,
} from "../../store/documentation/selectors";
const { Title, Text } = Typography;
const styleTextGray = { color: "#7A7D86" };

function DocumentationList() {
  const dispatch = useDispatch();
  const isLoader = useSelector(getLoaders);
  const docData = useSelector(getDocumentData);

  useEffect(() => {
    dispatch(resetData());
    dispatch(getDocumentsList(false));
  }, []);

  const columns = [
    createColumn("Название", "name", false, 100),
    createColumn("Описание", "description", false, 200),
    createColumn("Изменено", "date_modified", false, 50),
    createColumn(
      "Скачать",
      "file_name",
      (text, row) => (
        <Button
          size="small"
          type="text"
          icon={<IconDownloadFile style={{ color: "#8090B8" }} />}
          onClick={() => {
            downloadFile(row);
          }}
          title={text}
        ></Button>
      ),
      50
    ),
  ];

  const serviceDataArray = (type) => {
    if (docData) {
      const arr = docData?.results?.filter((item) => item.service === type);
      if (arr?.length === 0 || arr?.length === 0) return "Нет файлов";
      if (arr?.length > 10 && [11, 12, 13, 14].includes(arr?.length % 100))
        return arr[0].files?.length + " файлов";
      let last_num = arr?.length % 10;
      if (last_num === 1) return arr?.length + " файл";
      if ([2, 3, 4].includes(last_num)) return arr?.length + " файла";
      if ([5, 6, 7, 8, 9].includes(last_num)) return arr?.length + " файлов";
    }
  };
  const viewArr = (type) => {
    const arr = docData?.results?.filter((item) => item.service === type);
    return arr;
  };

  const downloadFile = async (file) => {
    let stringType = file.doc_file.slice(-5);
    let index = stringType.lastIndexOf(".");
    dispatch(
      getDocFile({
        name: file.name,
        typeFile: stringType.slice(index + 1),
        id: file.id
      })
    );
  };
  return (
    <Space
      direction="vertical"
      size={20}
      style={{ width: "100%", position: "relative" }}
    >
      {isLoader && <PreloaderNew />}
      <Card>
        <Space
          split={<Divider type="vertical" style={{ height: 52 }} />}
          size={80}
        >
          <Space direction="vertical" size={4}>
            <Text style={styleTextGray}>URL FILTERING</Text>
            <Text>{serviceDataArray("url_f")}</Text>
          </Space>
          <Space direction="vertical" size={4}>
            <Text style={styleTextGray}>THREAT LISTS</Text>
            <Text>{serviceDataArray("dtl")}</Text>
          </Space>
          <Space direction="vertical" size={4}>
            <Text style={styleTextGray}>SANDBOX</Text>
            <Text>{serviceDataArray("sandbox")}</Text>
          </Space>
          <Space direction="vertical" size={4}>
            <Text style={styleTextGray}>COMMON</Text>
            <Text>{serviceDataArray("common")}</Text>
          </Space>
        </Space>
      </Card>
      <Title level={5} style={{ margin: 0 }}>
        COMMON
      </Title>
      {docData?.results?.length > 0 && (
        <Table
          columns={columns}
          rowKey={(obj) => obj.id}
          size="small"
          pagination={false}
          style={{ overflowX: "auto" }}
          className="table"
          dataSource={viewArr("common")}
        />
      )}
      <Title level={5} style={{ margin: 0 }}>
        URL FILTERING
      </Title>
      {docData?.results?.length > 0 && (
        <Table
          columns={columns}
          rowKey={(obj) => obj.id}
          size="small"
          pagination={false}
          style={{ overflowX: "auto" }}
          className="table"
          // dataSource={() => docData?.results?.filter((item) => item.service === 'url_f')}
          dataSource={viewArr("url_f")}
        />
      )}
      <Title level={5} style={{ margin: 0 }}>
        THREAT LISTS
      </Title>
      {docData?.results?.length > 0 && (
        <Table
          columns={columns}
          rowKey={(obj) => obj.id}
          size="small"
          pagination={false}
          style={{ overflowX: "auto" }}
          className="table"
          dataSource={viewArr("dtl")}
        />
      )}
      <Title level={5} style={{ margin: 0 }}>
        SANDBOX
      </Title>
      {docData?.results?.length > 0 && (
        <Table
          columns={columns}
          rowKey={(obj) => obj.id}
          size="small"
          pagination={false}
          style={{ overflowX: "auto" }}
          className="table"
          dataSource={viewArr("sandbox")}
        />
      )}
    </Space>
  );
}

function createColumn(title, key, render, width) {
  return { title, key, dataIndex: key, render, width };
}

export default DocumentationList;
