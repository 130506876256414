import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Typography, Progress, message } from 'antd';
import { CloseOutlined, FileOutlined, CheckOutlined } from '@ant-design/icons';
import { IconCopy } from '../../icons';
import { IconErrorHash } from '../../icons/IconErrorHash';
import styles from './Sandbox.module.css'

const { Text } = Typography;
export default function FileCheckerResult({ isLoading, pinnedFileHash, errors, progress,
    fileSize, file, allowSendFileToCheck, handleTabChange }) {
    const oneMinuteRegex = /^[1-9]1$|^1$/;
    const twoThreeFourMinuteRegex = /^(?:[0-9]+[234]|[234])$/;
    const strokeColor = progress === 100 ? '#327FEF' : '#327FEF';
    const [fileUploadedAgo, setFileUploadedAgo] = useState(0);
    const startTimer = () => {
        const startTimestamp = new Date().getTime();
        const intervalRefresh = setInterval(() => {
            const now = new Date().getTime();
            const elapsedMilliseconds = now - startTimestamp;
            setFileUploadedAgo(Math.floor(elapsedMilliseconds / (1000 * 60)));
        }, 1000 * 60)
        return () => {
            clearInterval(intervalRefresh)
        }
    }

    useEffect(() => {
        setFileUploadedAgo(0)
        let timer = startTimer();
        return () => { timer() }
    }, [pinnedFileHash])


    const format = (percent) => {
        return `${percent}%`;
    };
    const [messageApi, contextHolder] = message.useMessage()
    const copyHash = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                messageApi.open({
                    type: 'info',
                    content: 'Скопировано!',
                    style: {
                        marginTop: '10px',
                    },
                    duration: 2
                });
            })
            .catch(() => {
                messageApi.open({
                    type: 'error',
                    content: 'Ошибка',
                    style: {
                        marginTop: '10px',
                    },
                    duration: 2
                });
            });
    };
    return (

        <Row span={24}>
            {contextHolder}
            <Col span={20}>
                {!allowSendFileToCheck && !file && <div style={{ width: '60%' }}>
                    <IconErrorHash />
                    <Text style={{ color: '#7A7D86', fontWeight: 400, fontSize: 13 }}>
                        Допустимый размер файла 50МБ
                    </Text>
                </div>}
                {file && allowSendFileToCheck &&
                    <div style={{
                        width: '94%', border: isLoading ? '1px solid white' : errors ? '1px solid #EB575780' : '1px solid #21965380', fontWeight: 500, borderRadius: '4px', padding: '14px 16px 14px 16px',
                        display: 'flex', justifyItems: 'center', alignContent: 'space-between'
                    }}>
                        <div style={{ display: 'flex', gap: 14, alignItems: 'center', width: '100%' }}>
                            {!isLoading && pinnedFileHash && <><FileOutlined style={{ fontSize: 36, alignSelf: 'center', color: '#219653' }} />
                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '14px', gap: 4, width: '90%' }}>
                                    <Text style={{ fontSize: 15, overflowWrap: 'break-word', wordBreak: 'break-word', flexWrap: 'nowrap' }}>{file?.name}</Text>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Text style={{ color: '#7A7D86', fontWeight: 500, fontSize: 12 }}>{pinnedFileHash}</Text>
                                        <Button
                                            size="small"
                                            type="text"
                                            icon={<IconCopy style={{ color: "#8090B8", width: 16, height: 16 }} />}
                                            onClick={() => {
                                                copyHash(pinnedFileHash)
                                            }}
                                        />
                                    </div>
                                </div></>}

                            {isLoading && <><FileOutlined style={{ fontSize: 36, alignSelf: 'center', color: '#4B4F55' }} />
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <div>
                                        <Text style={{ fontSize: 15, overflowWrap: 'break-word', wordBreak: 'break-word', flexWrap: 'nowrap' }}>{file?.name} </Text>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                        <Text style={{ color: '#7A7D86', fontWeight: 500, fontSize: 12 }}>{fileSize}</Text>
                                    </div>
                                </div></>}

                            {!isLoading && !pinnedFileHash && errors && <><FileOutlined style={{ fontSize: 36, alignSelf: 'center', color: '#EB5757' }} />
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <Text style={{ fontSize: 15, overflowWrap: 'break-word', wordBreak: 'break-word', width: '60%', flexWrap: 'nowrap' }}>{file?.name} </Text>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 5, justifySelf: 'flex-end' }}>
                                        <CloseOutlined style={{ color: '#EB5757', fontSize: 12 }} />
                                        <Text style={{ color: '#EB5757', fontSize: '12px' }}>{errors}</Text>
                                    </div>
                                </div> </>}
                        </div>
                        {!isLoading && pinnedFileHash && <div style={{ display: 'flex', width: '30%', flexDirection: 'column', alignItems: 'end', gap: '5px' }}>
                            <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                                <CheckOutlined style={{ fontSize: 10, color: '#219653' }} />
                                <Text style={{ textAlign: 'start', fontSize: 12, color: '#219653' }}>Файл загружен</Text>
                            </div>
                            <div>
                                <Text style={{ color: '#7A7D86', fontWeight: 500, fontSize: 12 }}>{fileSize}</Text><Text style={{ padding: '0 4px 0px 4px', color: '#7A7D86', fontWeight: 500, fontSize: 12 }}>-</Text>
                                <Text style={{ color: '#7A7D86', fontWeight: 500, fontSize: 12 }}>{fileUploadedAgo === 0 ? 'Менее минуты назад' : fileUploadedAgo} {
                                    twoThreeFourMinuteRegex.test(fileUploadedAgo) && fileUploadedAgo !== 12 && fileUploadedAgo !== 13 && fileUploadedAgo !== 14
                                        ? 'минуты назад' :
                                        oneMinuteRegex.test(fileUploadedAgo) && fileUploadedAgo !== 11 ? 'минуту назад' : fileUploadedAgo === 0 ? '' : 'минут назад'}</Text>
                            </div>

                        </div>}


                    </div>}


                {isLoading && !pinnedFileHash && <Col span={20}> <Progress
                    className={styles.customProgress}
                    style={{ width: '113%', paddingLeft: 22 }}
                    percent={progress}
                    strokeWidth={2}
                    strokeColor={strokeColor}
                    percentPosition={{
                        align: 'end',
                        type: 'outer'
                    }}
                    format={format}
                />
                </Col>}

                {pinnedFileHash && !isLoading && <div style={{ width: '60%' }}>
                    <Text style={{ color: '#7A7D86', fontWeight: 400, fontSize: 13 }}>
                        Файл успешно загружен, в <span style={{color: '#327FEF', cursor:'pointer'}} onClick={()=>{handleTabChange(2)}}>таблице проверок</span> появилась строка файла.
                        Проверка файла может занимать до 30 минут. Пожалуйста, подождите.
                    </Text>
                </div>}



            </Col>

        </Row>)


}